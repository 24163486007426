import { mapGetters } from 'vuex'
import Apis from '../api/system-setup'
export default {
  
  name: 'user',

  components: {
  },

  props: {
  },

  data() {
    return {
      userPageList: [],
      multipleSelection: [],
      searchUserValue: {
        departmentCode: '',
        pageCurrent: 1,
        pageSize: 100
      },
      userListTotal: 0,
      // 3
      isShowAddUser: false,
      addUserForm: {
        nickname: '',
        username: '',
        remarks: ''
      }
    }
  },

  computed: {
    ...mapGetters([
      'companyId'
    ])
  },

  watch: {
    companyId() {
      this.getUserPageList()
    }
  },

  mounted() {
    setTimeout(() => {
      this.getUserPageList()
    }, 500)
  },

  methods: {
    async getUserPageList() {
      this.searchUserValue.departmentCode = this.companyId
      const data = await Apis.findUserList(this.searchUserValue)
      // console.log(data)
      this.userPageList = data.list
      this.userListTotal = data.total
    },
    // 切换页码
    async handleSizeChangeData(val) {
      // console.warn(val)
      this.searchUserValue.pageCurrent = val
      this.getUserPageList()
    },
    // 选中
    selectItem(rows) {
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable.toggleRowSelection(it, false);
            return false;
          }
        })
        this.multipleSelection = newRows;
      } else {
        this.multipleSelection = rows;
      }
      // console.log(this.multipleSelection)
    },
    // 新增用户
    addUser() {
      delete this.searchUserValue.id
      this.addUserForm.nickname = ''
      this.addUserForm.username = ''
      this.addUserForm.remarks = ''
      this.isShowAddUser = true
      this.isUserEdit = false
    },
    // 新增用户确定
    async confirmUser() {
      if (this.isUserEdit) {
        await Apis.updateUser(this.addUserForm)
        this.$message.success('操作成功')
      } else {
        await Apis.addUser(this.addUserForm)
        this.$message.success('操作成功')
        this.searchUserValue.pageCurrent = 1
      }
      this.getUserPageList()
      this.isShowAddUser = false
    },
    // 修改用户
    amendUser() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection[0]
      if (data.username == 'admin') return
      this.isUserEdit = true
      this.addUserForm = JSON.parse(JSON.stringify(data))
      this.isShowAddUser = true
    },
    // 重置密码
    resetPwd() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection[0]
      this.$confirm('是否重置密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await Apis.resetPwd(data.id)
        this.$message.success('操作成功')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 删除用户
    deleteUser() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection[0]
      if (data.username == 'admin') return
      this.$confirm('是否删除该用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await Apis.delUser(data.id)
        this.$message.success('操作成功')
        this.getUserPageList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
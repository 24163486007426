<template>
  <div class="system-setup">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 1 -->
      <el-tab-pane
        v-if="isAdmin || isTissueAdmin || isProjectAdmin"
        label="组织机构"
        name="first"
      >
        <div class="content flex">
          <div class="tissue-pro flex-column">
            <div class="nameTit">组织项目</div>
            <div class="cont flex-1-auto">
              <el-tree
                class="filter-tree"
                :data="data"
                node-key="id"
                :props="defaultProps"
                default-expand-all
                :expand-on-click-node="false"
                @node-click="handleNodeClick"
                ref="tree"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span class="ovText" :title="node.label">{{ node.label }}</span>
                  <span class="flex-center-v">
                    <el-button
                      v-if="isAdmin && data.departmentType !== 'project'"
                      type="text"
                      size="mini"
                      @click.stop="() => addTissue(data)"
                      >新增组织</el-button
                    >
                    <el-button
                      v-if="isAdmin"
                      type="text"
                      size="mini"
                      @click.stop="() => append(data)"
                      >编辑</el-button
                    >
                    <el-button
                      v-if="isAdmin && data.departmentType !== 'company'"
                      type="text"
                      size="mini"
                      @click.stop="() => remove(data)"
                      >删除</el-button
                    >
                  </span>
                </span>
              </el-tree>
            </div>
          </div>
          <div class="tissue-user flex-column">
            <div
              class="nameTit flex-center-v flex-space-between"
              style="padding-right: 20px"
            >
              <div class="flex-center-v">
                <span>组织用户</span>
                <template
                  v-if="nodedata"
                >
                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    style="margin-left: 20px"
                    @click="chooseUser"
                    >选择用户</el-button
                  >
                  <el-button
                    type="danger"
                    size="mini"
                    plain
                    style="margin-left: 20px"
                    @click="romoverUser"
                    >移除用户</el-button
                  >
                </template>
              </div>
              <div class="flex-center-v">
                <el-select
                  v-model="roleType"
                  placeholder="请选择角色类型"
                  size="mini"
                  style="width: 150px"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    v-for="item in typeList"
                    :label="item.name"
                    :value="item.id"
                    :key="item.value"
                  ></el-option>
                </el-select>
                <el-button
                  type="primary"
                  size="mini"
                  style="margin-left: 20px"
                  @click="roleTypeSearch"
                  >查询</el-button
                >
              </div>
            </div>
            <div class="cont flex-1-auto">
              <el-table
                ref="multipleTable1"
                :data="userList"
                height="100%"
                style="width: 100%"
                @selection-change="selectItem1"
              >
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column label="姓名" width="150">
                  <template slot-scope="{ row }">
                    <span>{{ row.user.nickname }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="手机号码" width="150">
                  <template slot-scope="{ row }">
                    <span>{{ row.user.username }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="角色" width="450">
                  <template slot-scope="{ row }">
                    <span>{{ row.roleNameStr }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="备注">
                  <template slot-scope="{ row }">
                    <span>{{ row.user.remarks }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- 2 -->
      <el-tab-pane v-if="isAdmin" label="权限设置" name="second">
        <div class="content flex">
          <div class="tissue-pro flex-column">
            <div class="nameTit">角色名称</div>
            <div class="cont flex-1-auto">
              <el-tree
                class="filter-tree"
                :data="treeList"
                :props="defaultProps"
                default-expand-all
                :expand-on-click-node="false"
                @node-click="handleNodeClickTree"
                ref="tree"
              >
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
            </div>
          </div>
          <div class="tissue-user flex-column">
            <div class="nameTit flex-center-v">
              <span>组织用户</span>
              <el-button
                type="primary"
                size="mini"
                plain
                style="margin-left: 20px"
                @click="saveJurisdiction"
                >保存</el-button
              >
            </div>
            <div class="cont flex-1-auto">
              <el-table
                :data="tableJurisdictionList"
                height="100%"
                style="width: 100%"
              >
                <el-table-column
                  align="center"
                  prop="name"
                  label="模块名称"
                  width="180"
                ></el-table-column>
                <el-table-column align="center" label="查看" width="180">
                  <template slot-scope="{ row }">
                    <el-checkbox v-model="row.list[0].checked"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                  <template slot-scope="{ row }">
                    <el-checkbox v-model="row.list[1].checked"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="address"
                  label="备注"
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- 3 -->
      <el-tab-pane
        v-if="isAdmin || isTissueAdmin || isProjectAdmin"
        label="用户管理"
        name="third"
      >
        <div class="content content3 flex-column" style="padding: 10px">
          <div class="nameTit flex-center-v">
            <span>用户管理</span>
            <el-button
              type="primary"
              size="mini"
              plain
              style="margin-left: 20px"
              @click="addUser"
              >新增用户</el-button
            >
            <el-button
              type="primary"
              size="mini"
              plain
              style="margin-left: 20px"
              @click="amendUser"
              >修改用户</el-button
            >
            <el-button
              type="primary"
              size="mini"
              plain
              style="margin-left: 20px"
              @click="resetPwd"
              >重置密码</el-button
            >
            <el-button
              type="danger"
              size="mini"
              plain
              style="margin-left: 20px"
              @click="deleteUser"
              >删除用户</el-button
            >
          </div>
          <div class="cont flex-1-auto">
            <el-table
              ref="multipleTable"
              :data="userPageList"
              height="100%"
              style="width: 100%"
              @selection-change="selectItem"
            >
              <el-table-column type="selection" width="50"></el-table-column>
              <el-table-column
                prop="nickname"
                label="姓名"
                width="250"
              ></el-table-column>
              <el-table-column
                prop="username"
                label="手机号码"
                width="250"
              ></el-table-column>
              <el-table-column prop="remarks" label="备注"></el-table-column>
            </el-table>
          </div>
          <div class="flex-center" style="padding-top: 10px">
            <el-pagination
              v-if="userListTotal > 10"
              background
              :current-page.sync="searchUserValue.pageCurrent"
              :page-size="searchUserValue.pageSize"
              :total="userListTotal"
              layout="total, prev, pager, next"
              @current-change="handleSizeChangeData"
            ></el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 新增组织弹框 -->
    <el-dialog title="新增组织" :visible.sync="isShowAddTissue" width="30%" @close="TissueFormClose">
      <el-form ref="addTissueForm" :model="addTissueForm" :rules="tissueRules" class="demo-form-inline" label-width="80px">
        <el-form-item label="组织名称" prop="name">
          <el-input v-model="addTissueForm.name" placeholder="请输入组织名称" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="组织类型" prop="departmentType">
          <el-select
            v-model="addTissueForm.departmentType"
            :disabled="isEdit"
            placeholder="请选择组织类型"
            style="width: 100%"
          >
<!--            <el-option label="分公司" value="branch"></el-option>-->
            <el-option label="项目" value="project"></el-option>
            <el-option label="公司" value="company" disabled></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmTissue" size="small">确 定</el-button>
        <el-button @click="isShowAddTissue = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择用户弹框 -->
    <el-dialog title="选择用户" @close="close" :visible.sync="isShowChooseUser" width="40%" class="choose-user">
      <el-form ref="chooseForm" :model="chooseForm" class="demo-form-inline" label-width="80px">
        <el-form-item label="用户角色" prop="roleIdList">
          <el-select
            v-model="chooseForm.roleIdList"
            multiple
            placeholder="请选择用户角色"
            style="width: 100%"
          >
            <el-option
              v-for="item in typeListCan"
              :label="item.name"
              :value="item.id"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="height: 500px; overfloy-y: auto">
        <el-table
          ref="multipleTable2"
          :data="changeUserList"
          height="100%"
          style="width: 100%"
          @selection-change="selectItem2"
        >
          <el-table-column align="center" type="selection"></el-table-column>
          <el-table-column
            align="center"
            prop="nickname"
            label="姓名"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="手机号码"
          ></el-table-column>
        </el-table>
      </div>
      <div class="flex-center" style="padding-top: 10px">
        <el-pagination
          v-if="userDataTotal > 10"
          background
          :current-page.sync="changeUserForm.pageCurrent"
          :page-size="changeUserForm.pageSize"
          :total="userDataTotal"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeUser"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ChooseUserConfirm" size="small">确 定</el-button>
        <el-button @click="isShowChooseUser = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 新增用户弹框 -->
    <el-dialog title="新增用户" v-if="isShowAddUser" :visible.sync="isShowAddUser" width="30%">
      <el-form :model="addUserForm" class="demo-form-inline" label-width="80px">
        <el-form-item label="姓名">
          <el-input
            v-model="addUserForm.nickname"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model="addUserForm.username"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="addUserForm.remarks"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmUser" size="small">确 定</el-button>
        <el-button @click="isShowAddUser = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Tissue from "./mixins/tissue.js";
import Jurisdiction from "./mixins/jurisdiction.js";
import User from "./mixins/user.js";

import { mapGetters } from "vuex";
export default {
  name: "system-setup",

  components: {},

  props: {},
  mixins: [Tissue, Jurisdiction, User],
  data() {
    return {
      activeName: "first",
      defaultProps: {
        children: "children",
        label: "label",
      },
      expandDefault: [],
      checkDefault: [],
      isAdmin: false,
      isTissueAdmin: false,
      isProjectAdmin: false,
    };
  },

  computed: {
    ...mapGetters(["optionItem"]),
  },

  watch: {
    optionItem() {
      this.isAdmin = this.optionItem.roleNameList.includes("超级管理员");
      this.isTissueAdmin = this.optionItem.roleNameList.includes("组织管理员");
      this.isProjectAdmin = this.optionItem.roleNameList.includes("项目管理员");
    },
    checkDefault(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          document.querySelector(".el-tree-node__content").click();
        });
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.isAdmin = this.optionItem.roleNameList.includes("超级管理员");
      this.isTissueAdmin = this.optionItem.roleNameList.includes("组织管理员");
      this.isProjectAdmin = this.optionItem.roleNameList.includes("项目管理员");
    }, 500);
  },
  created() {
    setTimeout(() => {
    }, 1500);
  },

  methods: {
    // init() {
    // }
    // 切换tabs
    handleClick(tab, event) {
      console.log(tab.label, event);
      // 当切换的时候，获取树节点的第一个数据，然后发送请求
    },
  },
};
</script>

<style lang="stylus">
// @import "../../../assets/var"
.system-setup {
  .ovText {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
  .content {
    height: 100%;

    .nameTit {
      line-height: 40px;
      padding-left: 10px;
      background: #f5f5f5;
      text-align: left;
      font-size: 16px;
      border-radius: 6px;
    }

    .tissue-pro {
      width: 30%;
      height: 100%;
      padding: 10px;
      border-right: 1px solid #666666;

      .cont {
        padding: 10px 0;

        .custom-tree-node {
          font-size: 15px !important;
        }

        .el-tree-node__expand-icon {
          font-size: 19px !important;
        }

        .custom-tree-node {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          padding-right: 8px;
        }
      }
    }

    .tissue-user {
      width: 70%;
      height: 100%;
      padding: 10px;

      .cont {
        padding: 10px 0;
      }
    }
  }
}

.content {
  .el-table .cell {
    text-align: center;
  }

  .has-gutter {
    .el-checkbox {
      display: none;
    }
  }
}

.el-dialog {
  .el-dialog__header {
    background: #3396fb;
    display: flex;
    padding: 15px 20px;

    .el-dialog__title, .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff;
    }
  }

  .has-gutter {
    .el-checkbox {
      display: none;
    }
  }
}

.choose-user {
  .el-dialog__body {
    padding: 30px 20px 0;

    .el-form-item {
      margin-bottom: 10px;
    }

    .el-form-item__label {
      text-align: left;
    }

    .el-form-item__content {
      text-align: left;
    }

    .el-select {
      width: 50%;
    }
  }
}

.el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

.is-current {
  background: #f2f2f2;
}

.el-tree-node:focus {
  > .el-tree-node__content, .el-tree-node__expand-icon {
    color: #fff;
    background: #A9A9A9;
  }
}
</style>

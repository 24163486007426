import apis from '../api/system-setup'
import { mapGetters } from 'vuex'
export default {

  name: 'tissue',

  components: {
  },

  props: {
  },

  data() {
    return {
      data: [],
      isShowAddTissue: false,
      addTissueForm: {
        name: '',
        pid: '',
        code: '',
        departmentType: ''
      },
      tissueRules: {
        name: [{ required: false, message: '请输入组织名称', trigger: 'blur' }],
        departmentType: [{ required: false, message: '请选择组织类型', trigger: 'change' }]
      },
      isEdit: false,
      userList: [],
      roleType: '',
      typeList: [],
      nodedata: undefined,
      typeListCan: [],
      isShowChooseUser: false,
      chooseForm: {
        roleIdList: [],
        departmentId: '',
        userId: ''
      },
      chooseRules: {
        roleIdList: [{ required: false, message: '请选择用户角色', trigger: 'change' }]
      },
      changeUserList: [],
      changeUserForm: {
        departmentId: '',
        pageCurrent: 1,
        pageSize: 10
      },
      userDataTotal: 0,
      multipleSelection1: [],
      multipleSelection2: []
    }
  },

  computed: {
    ...mapGetters([
      'companyId'
    ])
  },

  watch: {
    companyId() {
      this.init()
      this.getTypes()
    }
  },

  mounted() {
    setTimeout(() => {
      this.init()
      this.getTypes()
    }, 500)
  },

  methods: {
    TissueFormClose() {
      this.$refs.addTissueForm.resetFields()
    },
    async getTypes() {
      this.typeList = await apis.findByRoleType()
      // console.log(this.typeList)
    },
    async init() {
      this.data = await apis.getNzTree(this.companyId)
      // console.log(this.data)
    },
    // 1-新增组织弹框
    addTissue(data) {
      // console.log(data)
      this.tissueData = data
      this.isEdit = false
      this.isShowAddTissue = true
    },
    // 确定弹框
    async confirmTissue() {
      if (this.isEdit) {
        await apis.updateZz(this.addTissueForm)
        this.init()
      } else {
        delete this.addTissueForm.id
        this.addTissueForm.pid = this.tissueData.id
        this.addTissueForm.code = this.tissueData.code
        await apis.addZz(this.addTissueForm)
        this.init()
      }
      this.$message.success('操作成功')
      this.isShowAddTissue = false
      const info = await apis.getUserInfo()
      this.$store.dispatch('settings/getUserInfo', info)
    },
    // 编辑
    append(data) {
      this.isEdit = true
      this.addTissueForm.name = data.label
      this.addTissueForm.id = data.id
      this.addTissueForm.departmentType = data.departmentType
      this.isShowAddTissue = true
    },
    // 删除
    remove(data) {
      this.$confirm('是否删除组织', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.delZz(data.id)
        this.$message.success('操作成功')
        this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 节点被点击事件
    async handleNodeClick(node) {
      // console.log(node)
      this.nodedata = node
      this.userList = await apis.findByDepartmentId(this.nodedata.code, this.roleType)
      // console.log(this.userList)
    },
    // 1-选择用户
    async chooseUser() {
      // console.log(this.nodedata)
      const roleType = this.nodedata.departmentType == 'project' ? 'project' : 'company'
      this.typeListCan = await apis.findByRoleType(roleType)
      this.changeUserForm.departmentId = this.nodedata.id
      const data = await apis.getUserList(this.changeUserForm)
      this.changeUserList = data.list
      this.userDataTotal = data.total
      // console.log(data)
      this.isShowChooseUser = true
    },
    async handleSizeChangeUser(val) {
      this.changeUserForm.pageCurrent = val
      const data = await apis.getUserList(this.changeUserForm)
      this.changeUserList = data.list
      this.userDataTotal = data.total
    },
    // 移除用户
    romoverUser() {
      if (this.multipleSelection1.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection1[0]
      this.$confirm('是否移除该用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.removeZzyh(data.id)
        this.$message.success('操作成功')
        this.userList = await apis.findByDepartmentId(this.nodedata.code, this.roleType)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 1-移除用户选中
    selectItem1(rows) {
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable1.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable1.toggleRowSelection(it, false);
            return false;
          }
        })
        this.multipleSelection1 = newRows;
      } else {
        this.multipleSelection1 = rows;
      }
      // console.log(this.multipleSelection1)
    },
    // 查询
    async roleTypeSearch() {
      this.userList = await apis.findByDepartmentId(this.nodedata.code, this.roleType)
    },
    // 1-选择用户选中
    selectItem2(rows) {
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable2.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable2.toggleRowSelection(it, false);
            return false;
          }
        })
        this.multipleSelection2 = newRows;
      } else {
        this.multipleSelection2 = rows;
      }
      // console.log(this.multipleSelection2)
    },
    async ChooseUserConfirm() {
      if (this.multipleSelection2.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection2[0]
      this.chooseForm.userId = data.id
      this.chooseForm.departmentId = this.nodedata.id
      await apis.addZzyh(this.chooseForm)
      this.$message.success('操作成功')
      this.isShowChooseUser = false
      this.userList = await apis.findByDepartmentId(this.nodedata.code, this.roleType)
    }
  }
}

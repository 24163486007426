import Apis from "../api/system-setup"

export default {

  name: 'jurisdiction',

  components: {
  },

  props: {
  },

  data() {
    return {
      treeList: [],
      tableJurisdictionList: []
    }
  },

  computed: {
  },

  watch: {
  },

  mounted() {
    setTimeout(() => {
      this.getTreeList()
    }, 500)
  },

  methods: {
    async getTreeList() {
      this.treeList = await Apis.findTree()
      // console.log(this.treeList)
    },
    // 节点被点击事件
    async handleNodeClickTree(node) {
      // console.log(node)
      if (!node.id) return
      this.jurisdictionData = node
      this.tableJurisdictionList = await Apis.findByRoleId(node.id)
      // console.log(this.tableJurisdictionList)
    },
    async close(){
      this.$refs.chooseForm.resetFields()
      this.chooseForm.roleIdList = await []
    },
    // 保存操作
    async saveJurisdiction() {
      const formData = {
        id: this.jurisdictionData.id,
        jsonArray: this.tableJurisdictionList
      }
      this.$confirm('是否保存操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await Apis.updatePermission(formData)
        this.$message.success('操作成功')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
